import api from '@/services/Http'
import { constructParams, extractSignal } from '@/helpers/api'

export async function getEntityHierarchy(entityID, params) {
  return (await api()).get(`/performance/okrs/entities/${entityID}/hierarchy`, {
    params,
  })
}

export async function getEntityNode(nodeID, params) {
  return (await api()).get(`/performance/okrs/entities/${nodeID}`, {
    params: params,
  })
}

export async function getEntityNodeKpi(nodeID, params) {
  return (await api()).get(`/performance/kpis/entities/${nodeID}`, {
    params: params,
  })
}

export async function getEntitieHistory(entityID, limit, offset) {
  const params = constructParams(['limit', 'offset'], { limit, offset })
  return (await api()).get(`/performance/okrs/entities/${entityID}/history`, {
    params,
  })
}

export async function getEntityComments(entityID, limit, offset = 0) {
  const params = constructParams(['limit', 'offset'], { limit, offset })
  return (await api()).get(`/performance/okrs/entities/${entityID}/comments`, {
    params,
  })
}

export async function postEntityComments(entityID, data) {
  return (await api()).post(
    `/performance/okrs/entities/${entityID}/comments`,
    data
  )
}

export async function deleteEntityComments(entityID, commentID) {
  return (await api()).delete(
    `/performance/okrs/entities/${entityID}/comments/${commentID}`
  )
}

export async function getEntityChildren(entityID, params) {
  return (await api()).get(`/performance/okrs/entities/${entityID}/children`, {
    params: params,
  })
}

export async function putEntityChildrenPriority({
  entityID,
  entityType,
  ...params
}) {
  return (await api()).put(
    `/performance/okrs/entities/${entityID}/children/${entityType}/priorization`,
    params?.params
  )
}

export async function getEntitiesHierarchySettings(data) {
  const params = constructParams(
    [
      'entityType',
      'entityClass',
      'entityTypeAllowedChildren',
      'entityClassAllowedChildren',
      'entityTypeExclusiveParam',
      'showMetadataRequiredFieldsForCycle',
      'root',
    ],
    data
  )

  return (await api()).get(`/performance/okrs/entities/hierarchy/settings`, {
    params,
  })
}

export async function getEntitiesAccessProfiles(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'workflow',
      'limit',
      'offset',
      'entityType',
      'entityClass',
      'label',
      'withSystemicTypes',
      'entityTypesExclude',
      'activeCycleIDs',
    ],
    data
  )

  return (await api()).get(`/performance/okrs/entities/access-profiles`, {
    params,
    ...signal,
  })
}

export async function getEntitiesWorkflowStatus(data) {
  const params = constructParams(
    ['entityType', 'entityClass', 'entityTypesExclude'],
    data
  )

  return (await api()).get(`/performance/okrs/entities/workflow-status`, {
    params: { ...params, limit: 500 },
  })
}

export async function getOKREntities(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'limit',
      'offset',
      'groupID',
      'cycleID',
      'cycleIDs',
      'title',
      'entityType',
      'entityClass',
    ],
    { ...data, title: data?.title || data?.text }
  )

  return (await api()).get(`/performance/okrs/entities/`, {
    params,
    ...signal,
  })
}

export async function GetEntitiesEndpoint({
  entityType,
  entityID = null,
  endpoint = null,
  ...params
}) {
  return (await api()).get(
    `/performance/okrs/entities/${entityType}s`
      .concat(entityID ? `/${entityID}` : '')
      .concat(endpoint ? `/${endpoint}` : ''),
    params
  )
}

export async function DeleteEntitiesEndpoint({
  entityType,
  entityID = null,
  endpoint = null,
  params,
}) {
  return (await api()).delete(
    `/performance/okrs/entities/${entityType}s`
      .concat(entityID ? `/${entityID}` : '')
      .concat(endpoint ? `/${endpoint}` : ''),
    { params }
  )
}

export async function UpdateEntitiesEndpoint({
  entityType,
  entityID = null,
  endpoint = null,
  ...params
}) {
  return (await api()).put(
    `/performance/okrs/entities/${entityType}s`
      .concat(entityID ? `/${entityID}` : '')
      .concat(endpoint ? `/${endpoint}` : ''),
    params?.params
  )
}

export const getEntitiesHierarchyPeople = async payload =>
  (await api()).get(
    `/performance/okrs/entities/${payload.entityID}/hierarchy/people?hierarchyMode=${payload.hierarchyMode}&limit=${payload.limit}&offset=${payload.offset}`
  )

export const exportEntitiesHierarchyPeople = async payload =>
  (await api()).get(
    `/performance/okrs/entities/${payload.entityID}/hierarchy/people/export`,
    {
      responseType: 'arraybuffer',
    }
  )

export const buildFetchEntitiesRequestBody = data =>
  constructParams(
    [
      'sortBy',
      'sortOrder',
      'limit',
      'offset',
      'directGroupOnly',
      'withHighlights',
      'progressType',
      'withoutUnmeasured',
      'referenceMonth',
      'title',
      'entityType',
      'entityClass',
      'entityID',
      'entityIDs',
      'groupID',
      'ownerIDs',
      'peopleGroupIDs',
      'workflowStatusID',
      'workflowStatusIDs',
      'cycleIDs',
      'personIDs',
      'tagIDs',
      'accessProfileIDs',
      'metadata',
      'followUpStatus',
      'sortByMetadataID',
      'invitationStatus',
      'status',
      'deleted',
      'hasInactivePerson',
      'withPeople',
    ],
    {
      ...data,
      ...(!!data?.directGroupOnly && {
        directGroupOnly: data.directGroupOnly === 'only',
      }),
    }
  )

export const exportEntities = async payload =>
  (await api()).post(
    `/performance/okrs/entities/export`,
    buildFetchEntitiesRequestBody(payload),
    {
      responseType: 'arraybuffer',
    }
  )

export const exportPeopleEntities = async payload =>
  (await api()).post(
    `/performance/okrs/entities/people/export`,
    buildFetchEntitiesRequestBody(payload),
    {
      responseType: 'arraybuffer',
    }
  )

export async function getOKREntitiesWithPost(data) {
  const signal = extractSignal(data)

  return (await api()).post(
    `/performance/okrs/entities/`,
    buildFetchEntitiesRequestBody(data),
    {
      ...signal,
    }
  )
}

export async function postQueueKeyResults(data) {
  const signal = extractSignal(data)

  return (await api()).post(
    `/performance/okrs/entities/key-results/series/queue`,
    buildFetchEntitiesRequestBody(data),
    {
      ...signal,
    }
  )
}

export const multiEditAddPeople = async data =>
  (await api()).put(`/performance/okrs/entities/batch/people`, data)

export const multiEditRemovePeople = async data =>
  (await api()).delete(`/performance/okrs/entities/batch/people`, {
    data: data,
  })

export const multiEditUpdateWorkflowStatus = async data =>
  (await api()).put(`/performance/okrs/entities/batch/workflow-status`, data)

export async function deleteMultipleOKREntities(entityIDs) {
  return (await api()).put(`/performance/okrs/entities/batch/delete`, {
    entityIDs,
  })
}

export const multiEditUpdateOwners = async data =>
  (await api()).put(`/performance/okrs/entities/batch/owners`, data)

export const multiEditUpdateMetadata = async data =>
  (await api()).put(`/performance/okrs/entities/batch/metadata`, data)

export async function ManagementReportByGroups(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'limit',
      'offset',
      'groupID',
      'cycleIDs',
      'name',
      'entityType',
      'entityClass',
      'sortBy',
      'sortOrder',
      'sortByWorkflowStatusID',
    ],
    data
  )

  return (await api()).get(`/performance/okrs/entities/reports/groups`, {
    params,
    ...signal,
  })
}

export async function ManagementReportByStrategicKeyResults(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'limit',
      'offset',
      'groupID',
      'cycleIDs',
      'title',
      'entityType',
      'entityClass',
      'sortBy',
      'sortOrder',
      'sortByWorkflowStatusID',
    ],
    data
  )

  return (await api()).get(
    `/performance/okrs/entities/reports/strategic-key-results`,
    {
      params,
      ...signal,
    }
  )
}

export async function GetCorresponsiblesAndPriorizationByPerson(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'groupID', 'cycleIDs', 'name', 'sortBy', 'sortOrder'],
    data
  )

  return (await api()).get(
    `/performance/okrs/entities/reports/corresponsibles-invitations`,
    {
      params,
      ...signal,
    }
  )
}

export async function ManagementReportByOwners(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    [
      'limit',
      'offset',
      'groupID',
      'cycleIDs',
      'name',
      'entityType',
      'entityClass',
      'sortBy',
      'sortOrder',
      'sortByWorkflowStatusID',
    ],
    data
  )

  return (await api()).get(`/performance/okrs/entities/reports/owners`, {
    params,
    ...signal,
  })
}

export async function getOkrsEntities(data) {
  return (await api()).post(`/performance/okrs/entities/`, data)
}

export async function putReactivateEntity(entityID, params) {
  return (await api()).put(
    `/performance/okrs/entities/${entityID}/reactivate`,
    params
  )
}

export async function listColumns(params) {
  return (await api()).get(`/performance/views-settings/list-columns`, {
    params,
  })
}

export async function reorderColumns(columns) {
  return (await api()).put(
    '/performance/views-settings/list-columns/positions',
    columns
  )
}

export async function getEntityPeople(entityId) {
  return (await api()).get(`performance/okrs/entities/:entityId/people`, {
    _params: { entityId },
  })
}

export async function getEntityOwners(entityId) {
  return (await api()).get(`performance/okrs/entities/${entityId}/owners`)
}
